/**
Basic Table Styles
 */
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

table.simple {
  width: 100%;
  border: none;
  border-spacing: 0;
  text-align: left;
}

table.simple thead tr th {
  padding: 16px 8px;
  font-weight: 500;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  white-space: nowrap;
}

table.simple thead tr th:first-child {
  padding-left: 24px;
}

table.simple thead tr th:last-child {
  padding-right: 24px;
}

table.simple tbody tr td {
  padding: 12px 8px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

table.simple tbody tr td:first-child {
  padding-left: 24px;
}

table.simple tbody tr td:last-child {
  padding-right: 24px;
}

table.simple tbody tr:last-child td {
  border-bottom: none;
}

table.simple.clickable tbody tr {
  cursor: pointer;
}

table.simple.clickable tbody tr:hover {
  background: rgba(0, 0, 0, 0.03);
}

table.simple.borderless {
  border: none;
}

table.simple.borderless tbody tr td {
  border: none;
}

table.simple.borderless thead tr th {
  border: none;
}

.holder .label {
  display: block;
}
.holder .holder {
  width: 400px;
  margin: 0 auto;
}
.holder .checkbox {
  position: relative;
  display: block;
  width: 100%;
  height: 30px;
  overflow: hidden;
}
.holder .check {
  width: 30px;
  height: 30px;
  position: absolute;
  opacity: 0;
}
.disabled.holder rect{
    fill: rgb(155, 155, 155);
    stroke: rgb(0, 0, 0);
    color: rgb(0, 0, 0);
}
.disabled.holder .path1 {
    color: rgb(0, 0, 0);
}

.holder .label span {
  position: absolute;
  margin-top: 15px;
}
.holder .path1 {
  stroke-dasharray: 400;
  stroke-dashoffset: 400;
  transition: 0.5s all;
  color: #6B2C57;
}
.holder .check:checked + label svg g path {
  stroke-dashoffset: 0;
}


.border-gray{
    border: 1px solid gray !important;
    border-width: 1px !important;
    box-sizing: none;
    border-color: gray !important;
    outline: unset !important;
}